/* eslint-disable max-len */

import React from 'react'
import styled from 'styled-components'

const BlockSVG = styled.svg`
    display: block;
`

export const Checkmark = (props) => (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
        <path
            d="M12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 16.1421 7.85786 19.5 12 19.5Z"
            fill="#2AC437"
        />
        <path
            d="M9.20323 12.4126L10.8534 14.2043L15.096 9.96166"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export const Xxx = ({ thin, ...props }) => (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        {thin ? (
            <path
                d="M12 12L23 1 12 12 1 1l11 11zm0 0L1 23l11-11 11 11-11-11z"
                stroke="#000"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        ) : (
            <path
                d="M12 12l7-7-7 7-7-7 7 7zm0 0l7 7-7-7-7 7 7-7z"
                stroke="#000"
                strokeWidth="1.5"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        )}
    </svg>
)

export const Play = (props) => (
    <BlockSVG {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M21.034 12.637l-12.936 8.26A1.364 1.364 0 0 1 6 19.747V4.378a1.364 1.364 0 0 1 2.098-1.15l12.936 8.26a.682.682 0 0 1 0 1.15z"
            fill="#0324FF"
            fillRule="evenodd"
        />
    </BlockSVG>
)
